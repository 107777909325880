import { FC } from 'react'
import { SearchFiltersHumanizedRefinement } from 'search'
import { Card as BaseCard } from 'shared/components/CardDeprecated'
import styled from 'styled-components'

import { IRefinementListProps } from '../IRefinementListProps'

import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import MobileListFilter from './MobileListFilter'
import DesktopListFilter from './DesktopListFilter'

const Card = styled(BaseCard)<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
`
const ListFilter: FC<IRefinementListProps> = ({
  items,
  refine,
  attribute,
  hide,
  qaAttrs,
  selectedFilters,
}) => {
  const handleRefinement = (value: string) => {
    sendTrackingEvent('FILTER_APPLY_TAP', {
      page: window?.location?.href,
      filter_name: SearchFiltersHumanizedRefinement()[attribute].toLowerCase(),
      filter_selection: value,
    })
    refine(value)
  }
  return items.length > 0 ? (
    <Card
      title={SearchFiltersHumanizedRefinement()[attribute]}
      isExpanded
      hide={hide}
      qaAttr={qaAttrs.filterName}
    >
      <MobileListFilter
        items={items}
        refine={handleRefinement}
        qaAttr={qaAttrs.mobileFilterName}
        selectedFilters={selectedFilters}
      />
      <DesktopListFilter
        items={items}
        refine={handleRefinement}
        qaAttr={qaAttrs.desktopFilterName}
        selectedFilters={selectedFilters}
      />
    </Card>
  ) : null
}

ListFilter.displayName = 'ListFilterContainer'

export default ListFilter
