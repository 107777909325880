import { getLocale, t } from 'localization'
import * as Yup from 'yup'

const TP = 'shared.addressValidations'

export const fullNameValidation = () => {
  const locale = getLocale()

  // japanese
  if (locale === 'ja-jp' || locale === 'en-jp') {
    return Yup.string()
      .matches(
        /^[^0-9!@#\\\$%\^&*\;+_=]+$/,
        t(`${TP}.nameValidation1`, 'Name cannot contain numbers and special characters'),
      )
      .required(t(`${TP}.nameValidation3`, 'Full name is a required field.'))
  }

  // default
  return Yup.string()
    .matches(
      /^[^0-9!@#\\\$%\^&*\;+_=]+$/,
      t(`${TP}.nameValidation1`, 'Name cannot contain numbers and special characters'),
    )
    .matches(/\S\s\S/, t(`${TP}.nameValidation2`, 'Name must contain first and last name'))
    .required(t(`${TP}.nameValidation3`, 'Full name is a required field.'))
}

export const getAddressValidationFields = ({
  isZipRequired = false,
  isStateRequired = true,
  isPhoneRequired = true,
  countryISOCode = '',
}) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const spec = {
    name: fullNameValidation(),
    address1: Yup.string().required(
      t(`${TP}.addressValidation`, 'Street Address is a required field.'),
    ),
    address2: Yup.string().nullable(),
    city: Yup.string().required(t(`${TP}.cityValidation`, 'city is a required field.')),
    postalCode: isZipRequired
      ? Yup.string().required(
          t(`${TP}.postalCodeValidation`, 'Zip / Postal Code is a required field.'),
        )
      : Yup.string().nullable(),
    countryCode: Yup.string().required(
      t(`${TP}.countryValidation`, 'Country or Region is a required field.'),
    ),
    state: isStateRequired
      ? Yup.string().required(t(`${TP}.stateValidation`, 'State is a required field.'))
      : Yup.string().nullable(),
    phone: isPhoneRequired
      ? Yup.string()
          .matches(phoneRegExp, t(`${TP}.phoneValidation`, 'Telephone is not valid.'))
          .required(t(`${TP}.phoneRequired`, 'Telephone is a required field.'))
      : Yup.string().nullable(),
  }

  switch (countryISOCode) {
    case 'MX':
      return {
        ...spec,
        customerIdNumber: Yup.string().required(
          t(`${TP}.taxId`, 'Personal Id Number is a required field.'),
        ),
        customerIdIssuingCountryCode: Yup.string().required(
          t(`${TP}.countryIssuingTaxId`, 'Id Issuing Country is a required field.'),
        ),
      }
  }

  return spec
}

export const emailValidation = () =>
  Yup.string()
    .email(t(`${TP}.emailValidation`, 'Email address is not valid.'))
    .required(t(`${TP}.emailRequired`, 'Email address is a required field.'))

type ValidationSchemaParams = {
  isPhoneRequired?: boolean
  /** Indicates if state is required in the form given the selected country. */
  isStateRequired?: boolean
  /** Indicates if zip code is required in the form given the selected country. */
  isZipRequired?: boolean
  /** Country code to use for specific field validations. */
  countryISOCode?: string
}

export const getAddressValidationSchema = ({
  isZipRequired = false,
  isStateRequired = true,
  isPhoneRequired = true,
  countryISOCode = '',
}: ValidationSchemaParams) =>
  Yup.object(
    getAddressValidationFields({
      isZipRequired,
      isStateRequired,
      isPhoneRequired,
      countryISOCode,
    }),
  )

export const addressErrorFieldMap = () =>
  new Map<string, string>([
    [
      t(`${TP}.addressNameValidation2`, 'Name cannot contain numbers and special characters.'),
      'fullName',
    ],
    [
      t(
        `${TP}.addressZipCodeValidation`,
        /* tslint:disable:max-line-length */
        'ZIP Code could not be found because neither a valid City, State, nor valid 5-digit ZIP Code was present.',
      ),
      'postalCode',
    ],
    [t(`${TP}.addressEmailValidation`, 'Email has already been taken'), 'email'], // for guest checkout
  ])

export const contactInformationErrorFieldMap = () =>
  new Map<string, string>([
    [
      t(`${TP}.addressNameValidation2`, 'Name cannot contain numbers and special characters.'),
      'fullName',
    ],
    [t(`${TP}.phoneValidation`, 'Phone Number is not valid.'), 'phoneNumber'],
    [t(`${TP}.addressEmailValidation`, 'Email has already been taken'), 'email'], // for guest checkout
  ])
