import { colors } from 'shared/theme'
import styled from 'styled-components'

const NavLink = styled.div`
  color: ${(props) => props.theme.textColor};
  font-size: 16px;
  font-weight: 500;
  padding: 12px;

  &:disabled {
    color: ${colors.mediumGray};
  }
`

export default NavLink
