import Link from 'next/link'
import { memo } from 'react'
import styled from 'styled-components'

import { useFetchUserSegmentsQuery } from 'api/consumerSegmentAccessApi'
import { logClickEventUtil } from 'mParticle/mParticleService'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { mediaQueries } from 'shared/theme'

type BannerInfo = {
  buttonLabel?: string
  buttonLink?: string
  id: number
  segmentId: string
  subtitle?: string
  title: string
  type: string
  uuid: string
}
export type CMSBannerData = BannerInfo[]

type BannerProps = {
  content?: CMSBannerData
}

const Banner = ({ content }: BannerProps) => {
  const { country: countryCode } = useShoppingRegionContext()
  const { data: userSegmentData } = useFetchUserSegmentsQuery({
    countryCode,
  })
  const segmentIds = userSegmentData?.userSegmentsList?.map((segment) => segment.id)
  // determine banner info based on segment id and CMS banner content
  let bannerInfo: BannerInfo | undefined =
    content?.find((banner) => segmentIds?.includes(banner.segmentId)) ||
    content?.find((banner) => !banner.segmentId) ||
    undefined

  if ((!bannerInfo?.uuid && !bannerInfo?.id) || bannerInfo.type === 'empty' || !segmentIds) {
    return null
  }
  if (typeof window === 'object') {
    if (bannerInfo) {
      window.sessionStorage.setItem('cmsBanner', JSON.stringify(bannerInfo))
    }
    if (!bannerInfo && window.sessionStorage.getItem('cmsBanner')) {
      bannerInfo = JSON.parse(window.sessionStorage.getItem('cmsBanner')!)
    }
  }
  if (!bannerInfo) {
    return null
  }

  const { buttonLabel, buttonLink, title, subtitle } = bannerInfo

  const baseBannerContent = (
    <>
      <Banner.Title>{title}</Banner.Title> {subtitle}{' '}
      {buttonLabel && <Banner.CTA>{buttonLabel}</Banner.CTA>}
    </>
  )

  return (
    <Banner.Container data-testid="globalBanner">
      {buttonLink ? (
        <Link href={buttonLink} legacyBehavior>
          <Banner.UnstyledLink aria-label="Seasonal Banner" onClick={logClickEventUtil}>
            {baseBannerContent}
          </Banner.UnstyledLink>
        </Link>
      ) : (
        baseBannerContent
      )}
    </Banner.Container>
  )
}

Banner.Container = styled.div`
  position: relative;
  display: block;
  background-color: black;
  text-align: center;
  font-weight: 500;
  color: white;
  line-height: 15px;
  padding: 12px 20px;
  z-index: 1999;
`

Banner.Title = styled.span`
  ${mediaQueries.mobile} {
    display: block;
  }
`

Banner.CTA = styled.span`
  text-decoration: underline;
`

Banner.UnstyledLink = styled.a`
  color: unset;
  cursor: pointer;
  text-decoration: none;
`

export default memo(Banner)
