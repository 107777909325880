import { useState } from 'react'
import { connectSortBy } from 'react-instantsearch-dom'
import { SVGIcon } from 'shared/components/Icons'
import { fonts, media } from 'shared/lib'
import colors from 'shared/lib/colors'
import styled from 'styled-components'
import { SearchQA } from 'shared/dataAttributes'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { t } from 'localization'

const TP = 'search.components.searchSort'
const TPForEnum = 'search.enums.search'

const StyledCaret = styled(SVGIcon)`
  position: absolute;
  right: 15px;
  width: 14px;
  pointer-events: none;
  top: 40%;
  transform: translateY(-50%);
`

const SelectBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: ${colors.FC2_WHITE};
  ${fonts.LINK};
  ${media.large`
    background-color: transparent;
    width: 200px;
    position: relative;
    bottom: 12px;
    left: 15px;
  `}
`

const Select = styled.select`
  appearance: none;
  width: 100%;
  height: 100%;
  padding: 10px 20px 9px 98px;
  border-radius: 0;
  font-weight: 500;
  background-color: ${colors.FC2_WHITE};
  border: 1px solid ${colors.FC2_BLACK};
  color: ${({ disabled }) => (disabled ? colors.FC2_GREY : colors.FC2_BLACK)};
  ${media.large`
    background-color: transparent;
    border: none;
  `}
`

const SortByLabel = styled.label`
  position: absolute;
  left: 40px;
`

interface SortOption {
  value: string
  label: string
}

export const SearchSort = ({
  items,
  currentRefinement,
  refine,
}: {
  items: SortOption[]
  currentRefinement: string
  refine: (a: string) => void
}) => {
  const [selection, setSelection] = useState(currentRefinement)
  const handleChange = (e) => {
    const value = e.currentTarget.value
    const selectedSortLabel = e.currentTarget
      .querySelector(`option[value=${value}]`)
      .innerHTML.toLowerCase()
      .replace(' ', '_')
    setSelection(value)
    sendTrackingEvent('SORT_APPLY_TAP', {
      page: window?.location?.href,
      sort_by: selectedSortLabel,
    })
    refine(value)
  }
  return (
    <SelectBox>
      <SortByLabel htmlFor="GridSortFilter">{t(`${TP}.sortBy`, 'Sort By:')}</SortByLabel>
      <Select
        id="GridSortFilter"
        data-qa={SearchQA.GridSortByFilter}
        value={selection}
        onChange={handleChange}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </Select>
      <StyledCaret name="caret" color={colors.FC2_GREY} />
    </SelectBox>
  )
}

export default connectSortBy(SearchSort)
