import { IconProps } from 'shared/components/Icons/types/IconProps'
import { colors } from 'shared/lib'

export const InfoIcon = ({
  color = colors.FC2_GREY,
  width = '16',
  height = '16',
  className = '',
  viewBox = '0 0 16 16',
}: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0Zm-7 8A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm.762-5.375H7.288V12h1.474v-1.375ZM5.715 5.543c-.117.33-.176.697-.176 1.1h1.254c0-.499.106-.895.319-1.188.22-.3.557-.451 1.012-.451.095 0 .205.022.33.066.125.044.242.114.352.209.11.095.202.224.275.385.08.154.121.341.121.561 0 .183-.026.341-.077.473-.044.132-.11.253-.198.363-.08.103-.176.198-.286.286a42.76 42.76 0 0 0-.33.286 3.18 3.18 0 0 0-.385.385c-.103.125-.19.271-.264.44a2.485 2.485 0 0 0-.165.594c-.03.22-.044.491-.044.814h1.188c0-.264.022-.488.066-.671.051-.183.121-.341.209-.473.088-.14.19-.264.308-.374.117-.11.246-.224.385-.341l.352-.308c.117-.11.22-.231.308-.363.095-.14.169-.3.22-.484a2.29 2.29 0 0 0 .088-.671c0-.352-.062-.667-.187-.946a1.892 1.892 0 0 0-.517-.693 2.087 2.087 0 0 0-.759-.429 2.748 2.748 0 0 0-.935-.154c-.403 0-.77.066-1.1.198a2.38 2.38 0 0 0-.836.539 2.439 2.439 0 0 0-.528.847Z"
      clipRule="evenodd"
    />
  </svg>
)
