import {
  ProductCondition,
  ProductSortType,
  SizeFilterSizeUnit,
} from '@goatapp/protos-gen-node/buying/consumer_search/api/v1/server_pb'
import { produce } from 'immer'
import Cookies from 'js-cookie'
import {
  consumerSearchConversionSizes,
  toConsumerSearchFilters,
} from 'layout/navigation/search/utils'
import { isEmpty, omit, omitBy } from 'lodash/fp'
import { SearchState } from 'react-instantsearch-core'
import { findResultsState } from 'react-instantsearch-dom/server'
import { AlgoliaIndex } from 'search'
import AlgoliaSearchClient from 'search/components/AlgoliaSearchClient'
import PageLevelInstantSearch from 'search/components/PageLevelInstantSearch'
import { STORAGE } from 'shared/enums/SitePreferences'

export const SORT_FILTER_MAP: {
  [key: string]: {
    name: string
    value: ProductSortType
    key: string
  }
} = {
  popularity: {
    name: 'Relevance',
    value: ProductSortType.POPULARITY_DESC,
    key: 'popularity',
  },
  trending: {
    name: 'Trending',
    value: ProductSortType.TRENDING_PURCHASE_DESC,
    key: 'trending',
  },
  recently_released: {
    name: 'Newest',
    value: ProductSortType.RELEASE_DATE_DESC,
    key: 'recently_released',
  },
  price_low_high: {
    name: 'Price (Low - High)',
    value: ProductSortType.PRICE_ASC,
    key: 'price_low_high',
  },
  price_high_low: {
    name: 'Price (High - Low)',
    value: ProductSortType.PRICE_DESC,
    key: 'price_high_low',
  },
}
interface ISeoMapping {
  algoliaType: AlgoliaType
  algoliaName: string
  urlName: string
}

enum AlgoliaType {
  RefinementList,
  Range,
}

const createSeoMapping = (
  algoliaType: AlgoliaType,
  algoliaName: string,
  urlName: string,
): ISeoMapping => ({ algoliaType, algoliaName, urlName })

export const SIZE_UNIT_MAP: Record<string, number> = {
  US: SizeFilterSizeUnit.SIZE_FILTER_SIZE_UNIT_US,
  UK: SizeFilterSizeUnit.SIZE_FILTER_SIZE_UNIT_UK,
  IT: SizeFilterSizeUnit.SIZE_FILTER_SIZE_UNIT_IT,
  FR: SizeFilterSizeUnit.SIZE_FILTER_SIZE_UNIT_FR,
  EU: SizeFilterSizeUnit.SIZE_FILTER_SIZE_UNIT_EU,
  JP: SizeFilterSizeUnit.SIZE_FILTER_SIZE_UNIT_JP,
}

export const getConsumerSearchSortType = (sortKey: string) => {
  if (sortKey.includes('newest')) {
    return SORT_FILTER_MAP.recently_released.value
  }

  if (sortKey.includes('price_asc')) {
    return SORT_FILTER_MAP.price_low_high.value
  }

  if (sortKey.includes('price_desc')) {
    return SORT_FILTER_MAP.price_high_low.value
  }

  if (sortKey.includes('trending')) {
    return SORT_FILTER_MAP.trending.value
  }

  return SORT_FILTER_MAP.popularity.value
}

const SeoMappingList: ISeoMapping[] = [
  createSeoMapping(AlgoliaType.RefinementList, 'brand_name', 'brand'),
  createSeoMapping(AlgoliaType.RefinementList, 'silhouette', 'model'),
  createSeoMapping(AlgoliaType.RefinementList, 'presentation_size', 'size'),
  createSeoMapping(AlgoliaType.RefinementList, 'size_us_men', 'size_men'),
  createSeoMapping(AlgoliaType.RefinementList, 'size_us_women', 'size_women'),
  createSeoMapping(AlgoliaType.RefinementList, 'size_us_youth', 'size_youth'),
  createSeoMapping(AlgoliaType.RefinementList, 'shoe_condition', 'condition'),
  createSeoMapping(AlgoliaType.RefinementList, 'color', 'color'),
  createSeoMapping(AlgoliaType.RefinementList, 'single_gender', 'gender'),
  createSeoMapping(AlgoliaType.RefinementList, 'category', 'category'),
  createSeoMapping(AlgoliaType.RefinementList, 'product_category', 'product_category'),
  createSeoMapping(AlgoliaType.RefinementList, 'product_type', 'product_type'),
  createSeoMapping(AlgoliaType.RefinementList, 'designer', 'designer'),
  createSeoMapping(AlgoliaType.RefinementList, 'collection_slugs', 'collection'),
  createSeoMapping(AlgoliaType.Range, 'lowest_price_cents', 'price_cents'),
  createSeoMapping(AlgoliaType.Range, 'lowest_price_cents_usd', 'price_cents_usd'),
  createSeoMapping(AlgoliaType.Range, 'release_year', 'year'),
]

const omitByEmptyValues = omitBy(isEmpty)

export const getSearchFilterArray = (query: string) => {
  if (!query) return []
  const searchFilterArray: any[] = []

  if (query) {
    const searchFilter = query.split(',')
    searchFilter.forEach((filter: any) => {
      if (filter?.length > 0) searchFilterArray.push(filter)
    })
  }
  return searchFilterArray
}
export const getTimeStamps = ({ year, month = 0, day = 1 }: any) => {
  return new Date(year, month, day).getTime()
}

export const getSortType = (type: string) => {
  if (type?.toLocaleLowerCase().includes('newest')) {
    return 2
  } else if (type?.toLocaleLowerCase().includes('price_desc')) {
    return 4
  } else if (type?.toLocaleLowerCase().includes('price_asc')) {
    return 3
  } else if (type?.toLocaleLowerCase().includes('trending')) {
    return 5
  }
  return 1
}
export const PRODUCT_CONDITION_MAP: any = {
  new_no_defects: {
    name: 'New',
    value: ProductCondition.NEW_NO_DEFECTS,
    key: 'new_no_defects',
  },
  new_with_defects: {
    name: 'New with Defect',
    value: ProductCondition.NEW_WITH_DEFECTS,
    key: 'new_with_defects',
  },
  goat_clean: {
    name: 'GOAT Clean',
    value: ProductCondition.GOAT_CLEAN,
    key: 'goat_clean',
  },
  used: {
    name: 'Used',
    value: ProductCondition.USED,
    key: 'used',
  },
}

const omitRefinementList = omitBy((refinement) => {
  const isRefinementEmpty = isEmpty(refinement)
  const isEmptyArray = Array.isArray(refinement) && isEmpty(omitByEmptyValues(refinement))

  return isRefinementEmpty || isEmptyArray
})

const adjustRefinementListProperties = (searchState: SearchState) => {
  if (!isEmpty(searchState.refinementList)) {
    SeoMappingList.filter((x) => x.algoliaType === AlgoliaType.RefinementList).forEach(
      (mapping) => {
        if (
          searchState.refinementList![mapping.algoliaName] &&
          !isEmpty(omitByEmptyValues(searchState.refinementList![mapping.algoliaName]))
        ) {
          searchState[mapping.urlName] = searchState.refinementList![mapping.algoliaName].join(',')
        }
      },
    )
  }
}

const adjustRangeProperties = (searchState: SearchState) => {
  if (!isEmpty(searchState.range)) {
    SeoMappingList.filter((x) => x.algoliaType === AlgoliaType.Range).forEach((mapping) => {
      if (searchState.range![mapping.algoliaName]) {
        if (searchState.range![mapping.algoliaName].min) {
          searchState[`${mapping.urlName}_min`] = searchState.range![mapping.algoliaName].min
        }
        if (searchState.range![mapping.algoliaName].max) {
          searchState[`${mapping.urlName}_max`] = searchState.range![mapping.algoliaName].max
        }
      }
    })
  }
}

export const searchStateToUrl = (searchState: SearchState) => {
  if (isEmpty(searchState)) {
    return {}
  }

  return produce(searchState, (draftUrlParams: Record<string, any>) => {
    draftUrlParams.refinementList = omitRefinementList(draftUrlParams.refinementList)

    if (draftUrlParams.page === 1) {
      delete draftUrlParams.page
    }

    if (draftUrlParams.configure) {
      draftUrlParams.configure = omit(['hitsPerPage', 'distinct', 'filters'])(
        draftUrlParams.configure,
      )
    }

    if (isEmpty(draftUrlParams.configure)) {
      delete draftUrlParams.configure
    }

    // Friendly SEO for refinement list
    adjustRefinementListProperties(draftUrlParams)

    // Friendly SEO for range
    adjustRangeProperties(draftUrlParams)

    delete draftUrlParams.refinementList
    delete draftUrlParams.range
  })
}

const defaultSearchState = {
  page: 1,
  query: undefined,
  refinementList: {},
  range: {},
}

export const urlToSearchState = (urlParams: any) => {
  if (isEmpty(urlParams)) {
    return defaultSearchState
  }

  const { query = '', page = 1 } = urlParams

  const refinementList: Record<string, any> = {}
  const range: Record<string, any> = {}

  SeoMappingList.forEach((mapping) => {
    // for refinementList object
    if (mapping.algoliaType === AlgoliaType.RefinementList && urlParams[mapping.urlName]) {
      const rawValue = urlParams[mapping.urlName]
      // `qs` does not return an array when there's a single value.
      const refinementValue = Array.isArray(rawValue)
        ? rawValue
        : rawValue.split(',').filter(Boolean)
      refinementList[mapping.algoliaName] = refinementValue.map(decodeURIComponent)
    }
    // for range object
    if (
      mapping.algoliaType === AlgoliaType.Range &&
      (urlParams[`${mapping.urlName}_min`] || urlParams[`${mapping.urlName}_max`])
    ) {
      range[mapping.algoliaName] = {
        min: urlParams[`${mapping.urlName}_min`],
        max: urlParams[`${mapping.urlName}_max`],
      }
    }
  })

  return {
    ...defaultSearchState,
    query: query ? decodeURIComponent(query) : undefined,
    page,
    refinementList,
    range,
  }
}

export const getResultsState = async (
  initialSearchState: any,
  searchState = {},
  hitsPerPage = 8,
) => {
  const isSearchStateEmpty = Object.keys(searchState).length === 0
  const resultsState = await findResultsState(PageLevelInstantSearch, {
    hitsPerPage,
    indexName: AlgoliaIndex.Relevance,
    initialSearchState: isSearchStateEmpty ? {} : initialSearchState,
    searchClient: AlgoliaSearchClient,
    searchState: isSearchStateEmpty ? initialSearchState : searchState,
  })

  // If there are no relevant products in resultsState, get trending products
  const trendingResultsState = await (async () => {
    if (resultsState?.rawResults?.[0]?.nbHits === 0) {
      return await findResultsState(PageLevelInstantSearch, {
        indexName: AlgoliaIndex.Trending,
        searchClient: AlgoliaSearchClient,
        searchState: {},
      })
    }
    return null
  })()

  return { resultsState, trendingResultsState }
}

export type ISearchState = {
  query: string
  page: number
  refinementList: {
    [key: string]: string[]
  }
  range: {
    [key: string]: {
      min: string
      max: string
    }
  }
}

export const getConsumerSearchFilters = ({
  searchState,
  cookies = {},
  queryParams = {},
  pageLimit = '30',
  isClientOnly = false,
}: {
  searchState: ISearchState
  cookies: Record<string, string>
  queryParams: Record<string, string> | any
  pageLimit?: string
  isClientOnly?: boolean
}) => {
  return toConsumerSearchFilters({
    isClientOnly,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...searchState,
    query: queryParams.query || searchState?.query,
    sortType: getConsumerSearchSortType((queryParams.sortBy || '') as string),
    type: [],
    country: cookies.country,
    pageLimit: pageLimit,
    currency:
      cookies.currencyCode || cookies.currency
        ? JSON.parse(cookies?.currency || '{}').isoCode
        : 'USD',
  })
}

export const getClientConsumerSearchFilters = (searchState: any, query: any) => {
  const currentSearchParams: any = getConsumerSearchFilters({
    searchState: searchState,
    cookies: {
      currency: Cookies.get(STORAGE.CURRENCY) as string,
      country: Cookies.get(STORAGE.COUNTRY) as string,
    },
    queryParams: query || {},
    pageLimit: '30',
    isClientOnly: true,
  })

  if (query?.max_price) {
    currentSearchParams.priceCentsMax = parseFloat(query.max_price as string) * 100
  }

  if (query?.min_price) {
    currentSearchParams.priceCentsMin = parseFloat(query.min_price as string) * 100
  }

  if (searchState?.index || query?.sortBy) {
    currentSearchParams.sortType = getSortType(
      (query.sortBy as string) || (searchState?.index as string) || '',
    )
  }

  if (query?.page) {
    currentSearchParams.pageNumber = query.page
  }

  currentSearchParams.productFilter.productTypes = getSearchFilterArray(
    searchState?.product_type?.join(',') || (query?.product_type as string),
  )

  currentSearchParams.productFilter.brands = getSearchFilterArray(
    (searchState?.refinementList?.brand || searchState?.refinementList?.brand_name)?.join(',') ||
      (query?.brand as string),
  )
  currentSearchParams.productFilter.colors = getSearchFilterArray(query?.color as string as string)
  currentSearchParams.productFilter.conditions = getSearchFilterArray(
    query?.condition as string,
  ).map((condition) => PRODUCT_CONDITION_MAP[condition].value)
  if (query?.year_max) {
    currentSearchParams.productFilter.releaseDateEnd = getTimeStamps({
      year: parseInt(query?.year_max as string),
    })
  }
  if (query?.year_min) {
    currentSearchParams.productFilter.releaseDateStart = getTimeStamps({
      year: parseInt(query?.year_min as string),
    })
  }
  currentSearchParams.productFilter.silhouettesList = getSearchFilterArray(
    searchState?.refinementList?.silhouette?.join(',') || (query?.model as string),
  )

  currentSearchParams.collectionSlug = searchState?.refinementList?.collection_slugs?.[0] || ''

  const currentSize: any = []
  if (query?.size_women) {
    currentSize.push({ _us_women: getSearchFilterArray(query?.size_women as string) })
  }

  if (query?.size_men) {
    currentSize.push({ _us_men: getSearchFilterArray(query?.size_men as string) })
  }

  if (query?.size_youth) {
    currentSize.push({ _us_youth: getSearchFilterArray(query?.size_youth as string) })
  }

  // sizes
  if (currentSize.length > 0) {
    currentSearchParams.productFilter.sizes = consumerSearchConversionSizes(
      currentSize,
      currentSearchParams?.productFilter?.categories?.[0] === 'apparel' ? 'tops' : 'sneakers',
    )
  }

  currentSearchParams.productFilter = JSON.stringify(currentSearchParams.productFilter)

  return currentSearchParams
}

export const getAllfilters = ({
  queryString,
  collection,
  categories,
  releaseYears,
  activities,
  conditions,
  colors,
  genders,
  releaseDateStart,
  releaseDateEnd,
  brands,
  silhouettes,
  types,
  priceMin,
  priceMax,
  vector,
}: any = {}) => {
  let filtersParams = {
    categories: getSearchFilterArray(categories as string),
    releaseYears: getSearchFilterArray(releaseYears as string),
    activities: getSearchFilterArray(activities as string),
    conditions: getSearchFilterArray(conditions as string).map(
      (condition) => PRODUCT_CONDITION_MAP[condition].value,
    ),
    brands: getSearchFilterArray(brands),
    silhouettesList: getSearchFilterArray(silhouettes),
    colors: getSearchFilterArray(colors as string),
    genders: getSearchFilterArray(genders as string),
    releaseDateStart: getSearchFilterArray(releaseDateStart as string)[0]
      ? getTimeStamps({
          year: parseInt(getSearchFilterArray(releaseDateStart as string)[0]),
        })
      : undefined,
    releaseDateEnd: getSearchFilterArray(releaseDateEnd as string)[0]
      ? getTimeStamps({
          year: parseInt(getSearchFilterArray(releaseDateEnd as string)[0]),
        })
      : undefined,
    productTypes: getSearchFilterArray(types as string),
  }

  const query = {
    salesChannelId: '2',
    queryString: (queryString as string) || '',
    sortType: '1',
    pageLimit: '1',
    pageNumber: '1',
    includeAggregations: 'true',
    collectionSlug: collection || '',
    priceCentsMin:
      getSearchFilterArray(priceMin as string)[0] !== 'NaN'
        ? getSearchFilterArray(priceMin as string)[0]
        : undefined,
    priceCentsMax:
      getSearchFilterArray(priceMax as string)[0] !== 'NaN'
        ? getSearchFilterArray(priceMax as string)[0]
        : undefined,
    productFilter: JSON.stringify(filtersParams),
    vector: vector || '',
    country: Cookies.get(STORAGE.COUNTRY) || 'US',
    currency: Cookies.get(STORAGE.CURRENCYCODE) || 'USD',
  }

  return query
}
