import { apiSlice } from 'api/apiSlice'
import { pruneUserFields } from 'server/utils/auth/pruneUserFields'
import { User } from 'shared/types/User'

export const usersApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // 'createGuest' mutation uses '/users/create_guest' api but masked as '/create_guest' api path
    // uses createGuestController to create a new guest user and set necessary cookies
    createGuest: build.mutation({
      query: (body) => ({
        app: 'sneakers',
        data: body,
        method: 'POST',
        url: '/web-api/v1/create_guest',
      }),
    }),
    editUser: build.mutation({
      query: ({ id, ...body }) => ({
        app: 'sneakers',
        data: body,
        method: 'PUT',
        url: `/web-api/v1/users/${id}`,
      }),
      transformResponse: (response: User) => {
        return pruneUserFields(response)
      },
    }),
    getUser: build.query<User, void>({
      query: () => ({
        app: 'sneakers',
        cache: false,
        method: 'GET',
        url: `/web-api/v1/get_user?timestamp=${new Date().getTime()}`,
      }),
    }),
    // 'login' mutation uses '/users/sign_in' api but masked as '/login' api path
    // uses loginController to retrieve proper login response and set necessary cookies
    login: build.mutation<
      Pick<User, 'email' | 'id' | 'isGuest' | 'name' | 'username'>,
      { login: string; password: string }
    >({
      query: (body) => ({
        app: 'sneakers',
        data: body,
        method: 'POST',
        url: '/web-api/v1/login',
      }),
    }),
    // 'logout' mutation uses '/users/sign_out' api but masked as '/logout' api path
    // uses logoutController to destroy user's session cookies
    logout: build.mutation<void, void>({
      query: () => ({
        app: 'sneakers',
        method: 'POST',
        url: '/web-api/v1/logout',
      }),
    }),
    // 'registerUser' mutation uses '/users' api but masked as '/register' api path
    // uses registerUserController to create a new user and set necessary cookies
    registerUser: build.mutation<User, { email: string; name: string; password: string }>({
      query: (body) => ({
        app: 'sneakers',
        data: body,
        method: 'POST',
        url: '/web-api/v1/register',
      }),
    }),
    // 'upgradeGuest' mutation uses '/users/upgrade_guest' but masked as '/upgrade_guest' api path
    // uses upgradeGuestController to convert a guest user and set necessary cookies
    upgradeGuest: build.mutation({
      query: (body) => ({
        app: 'sneakers',
        data: body,
        method: 'POST',
        url: '/web-api/v1/upgrade_guest',
      }),
    }),
  }),
})

export const {
  useCreateGuestMutation,
  useEditUserMutation,
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
  useRegisterUserMutation,
  useUpgradeGuestMutation,
} = usersApi
