import { UserType } from 'shared/types/User'

import { selectCurrentUser, selectUserIsAuthenticated } from 'store/authSlice'
import { useAppSelector } from 'store/hooks'

export const useUser = () => {
  const currentUser = useAppSelector(selectCurrentUser)
  const isAuthenticated = useAppSelector(selectUserIsAuthenticated)

  const currentUserType: UserType = (() => {
    if (isAuthenticated) {
      return 'loggedInUser'
    }
    if (currentUser?.isGuest) {
      return 'registeredGuest'
    }
    return 'guest'
  })()

  return { currentUser, currentUserType, isAuthenticated }
}
