/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import { lowerCase, startCase } from 'lodash'
import { get, orderBy, pipe, toLower } from 'lodash/fp'
import { Fragment, useCallback } from 'react'

import { useRouter } from 'next/router'
import { SearchFiltersRefinement } from 'search'
// import CategoryFilter from 'search/components/SearchFilters/Filters/CategoryFilter'
import useCurrency from 'shared/hooks/useCurrency'
// import ColorFilter from './Filters/ColorFilter'
// import ConditionFilterContainer from './Filters/Condition/ConditionFilterContainer'
import ListFilter from './ListFilter/ListFilterContainer'
import { SearchQA } from 'shared/dataAttributes'

import SizeSelectionFilter from './SizeFilter/SizeSelectionFilter'
import ColorFilter from './ColorFilter/ColorFilterContainer'
import PresentationSizeFilter from './SizeFilter/PresentationSizeFilter'
import { sortByShirtSizeOrder } from '../Filters/Size/ShirtSizeOrder'
import CategoryFilter from './CategoryFilter'
import PriceFilterContainer from './PriceFilterContainer'

// import PriceFilterContainer from './Filters/Price/PriceFilterContainer'

// import SizeSelectionFilter from './Filters/Size/SizeSelectionFilter'
// import YearFilter from './Filters/YearFilter'

/**
 * 40 will cover all valid sizes and on the off chance there are more than 40 that
 * we'd want to display in the current UI design at one time, more than 40 would be an
 * unreasonable amount for the eye to search through
 */
const SizeFacetMax = 40
/**
 * WARNING:
 * `limit` will set the `maxValuesPerFacet` global limit, and thus those facets are
 * requested anyway, for all facets.
 * https://github.com/algolia/react-instantsearch/issues/2895
 */
// const ModelFacetMax = 10
// const BrandFacetMax = 10
// const ColorFacetMax = 20
// const CategoryFacetMax = 20
// const ColorDisplayMax = 10

export const SIZE_MAP = {
  '100': 'xxxs',
  '100.5': 'xxxs/xxs',
  '101': 'xxs',
  '101.5': 'xxs/xs',
  '102': 'xs',
  '102.5': 'xs/s',
  '103': 's',
  '103.5': 's/m',
  '104': 'm',
  '104.5': 'm/l',
  '105': 'l',
  '105.5': 'l/xl',
  '106': 'xl',
  '106.5': 'xl/2xl',
  '107': 'xxl',
  '107.5': '2xl/3xl',
  '108': '3xl',
  '108.5': '3xl/4xl',
  '109': '4xl',
  '200': 'one size',
}

function isValidSize(numString: string, options: { isApparel: boolean }) {
  const num = parseFloat(numString)

  if (!options?.isApparel && (isNaN(num) || (!isNaN(num) && (num > 25 || num < 0)))) {
    return false
  }

  return !isNaN(num) && (num % 1 === 0 || num % 1 === 0.5)
}
const getMinYear = (list = []) => {
  return Math.min(...list.map((i) => parseInt(i.name || 1)))
}

const getMaxYear = (list = []) => {
  const m = Math.max(...list.map((i) => parseInt(i.name || 1))) || 1
  if (m === 1) return undefined

  return m
}

const ConsumerFiltersSection = ({ filters, silhouettesList, hiddenFilters, isApparel }: any) => {
  const sortNumericLabel = (items) =>
    items.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }))

  const sortAlphanumericLabel = (items) => {
    // const topItems = items.slice(0, ModelFacetMax)
    return items.sort((a, b) =>
      a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' }),
    )
  }

  const orderByCondition = (items) => {
    return items.map((item) => ({ ...item, label: startCase(item.label.replace(/_/g, ' ')) }))
  }
  const orderByBrand = (items) => {
    // const topItems = items.slice(0, BrandFacetMax)
    return orderBy([({ label }) => lowerCase(label)], ['asc'], items)
  }

  // Get top 10 and sort them alphabetically
  const sortColorByCountThenAlpha = (items) =>
    items.sort((a, b) =>
      a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: 'base' }),
    )

  const { selectedCurrency, loading, error } = useCurrency()
  const currencyIsoCode = pipe(get('isoCode'), toLower)(selectedCurrency)
  const router = useRouter()

  const handleQueryChange = (attr, value) => {
    // e.preventDefault();
    // e.stopPropagation();

    const selectedTypes = new Set(((router?.query[attr] || '') as string).split(','))
    let newQuery: any = { ...router?.query }
    const newTypes = new Set(selectedTypes)
    if (newTypes.has('')) {
      newTypes.delete('')
    }
    if (newTypes.has(value)) {
      newTypes.delete(value)
      // since it's root category delete all types associated with it
    } else {
      newTypes.add(value)
    }
    newQuery = {
      ...newQuery,
      [attr]: [...(newTypes as any)].join(',') as string,
    }

    if (attr !== 'page') {
      delete newQuery.page
    }

    if (newQuery[attr] === '') {
      delete newQuery[attr]
    }

    delete newQuery.slug

    void router.push(
      `${window.location.pathname}?` + new URLSearchParams(newQuery).toString(),
      undefined,
      {
        shallow: true,
      },
    )
  }

  const handleYearChange = (value) => {
    const newQuery: any = { ...router?.query }

    if (value.min) {
      newQuery.year_min = value.min
    } else {
      delete newQuery.year_min
    }

    if (value.max) {
      newQuery.year_max = value.max
    } else {
      delete newQuery.year_max
    }

    delete newQuery.page

    void router.push(
      `${window.location.pathname}?` + new URLSearchParams(newQuery).toString(),
      undefined,
      {
        shallow: true,
      },
    )
  }

  const handlePriceChange = (value) => {
    const newQuery: any = { ...router?.query }

    if (value.min) {
      newQuery.min_price = value.min
    } else {
      delete newQuery.min_price
    }

    if (value.max) {
      newQuery.max_price = value.max
    } else {
      delete newQuery.max_price
    }

    delete newQuery.page

    void router.push(
      `${window.location.pathname}?` + new URLSearchParams(newQuery).toString(),
      undefined,
      {
        shallow: true,
      },
    )
  }

  const getSelectedFilters = useCallback(
    (attr) => {
      return new Set(((router?.query[attr] || '') as string).split(','))
    },
    [router?.query],
  )

  const getSizes = (allSizes, type) => {
    return (
      allSizes.filter((s) => s.name.toLowerCase() === type.toLowerCase())?.[0]
        ?.availableSizesList || []
    )
  }

  return (
    <Fragment>
      <ListFilter
        hide={
          isApparel || (!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.Silhouette))
        }
        attribute={SearchFiltersRefinement.Silhouette}
        qaAttrs={{
          filterName: SearchQA.FilterModel,
          mobileFilterName: SearchQA.MobileFilterModelLabel,
          desktopFilterName: SearchQA.DesktopFilterModel,
        }}
        items={sortAlphanumericLabel(
          (silhouettesList || []).map((filter) => {
            return {
              label: filter.name,
              value: filter.name,
            }
          }),
        )}
        selectedFilters={getSelectedFilters('model')}
        refine={(va) => {
          handleQueryChange('model', va)
        }}
      />
      <ListFilter
        hide={isApparel || (!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.Brand))}
        attribute={SearchFiltersRefinement.Brand}
        qaAttrs={{
          filterName: SearchQA.FilterBrand,
          mobileFilterName: SearchQA.MobileFilterBrandLabel,
          desktopFilterName: SearchQA.DesktopFilterBrand,
        }}
        selectedFilters={getSelectedFilters('brand')}
        items={orderByBrand(
          (filters?.brandsList || []).map((filter) => {
            return {
              label: filter.name,
              value: filter.name,
            }
          }),
        )}
        refine={(va) => {
          handleQueryChange('brand', va)
        }}
      />

      <PresentationSizeFilter
        hide={!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.PresentationSizes)}
        attribute={SearchFiltersRefinement.PresentationSizes}
        isExpanded
        selectedFilters={getSelectedFilters('size_men')}
        items={sortByShirtSizeOrder(
          (
            getSizes(
              filters?.sizesList?.find((size) => size?.name === 'tops')?.gendersList || [],
              'US_men',
            ) || []
          )
            .map((filter) => {
              return {
                label: SIZE_MAP[filter.name] || filter.name,
                value: filter.name,
              }
            })
            .filter((aSize: { label: string; value: string }) =>
              isValidSize(aSize.value, { isApparel: true }),
            ),
        )}
        refine={(va) => {
          handleQueryChange('size_men', va)
        }}
      />
      <SizeSelectionFilter
        hide={isApparel || (!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.MenSizes))}
        attribute={SearchFiltersRefinement.MenSizes}
        isExpanded={!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.MenSizes)}
        selectedFilters={getSelectedFilters('size_men')}
        items={sortNumericLabel(
          (getSizes(filters?.sizesList?.[0]?.gendersList || [], 'US_men') || [])
            .map((filter) => {
              if (SIZE_MAP[filter.name] || !isValidSize(filter.name, { isApparel: false })) {
                return undefined
              }
              return {
                label: filter.name,
                value: filter.name,
              }
            })
            .filter((i: any) => i),
        )}
        refine={(va) => {
          handleQueryChange('size_men', va)
        }}
      />
      <SizeSelectionFilter
        hide={
          isApparel || (!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.WomenSizes))
        }
        attribute={SearchFiltersRefinement.WomenSizes}
        limit={SizeFacetMax}
        isExpanded={!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.WomenSizes)}
        selectedFilters={getSelectedFilters('size_women')}
        items={sortNumericLabel(
          (getSizes(filters?.sizesList?.[0]?.gendersList || [], 'US_women') || [])
            .map((filter) => {
              if (SIZE_MAP[filter.name] || !isValidSize(filter.name, { isApparel: false })) {
                return undefined
              }
              return {
                label: filter.name,
                value: filter.name,
              }
            })
            .filter((i: any) => i),
        )}
        refine={(va) => {
          handleQueryChange('size_women', va)
        }}
      />
      <SizeSelectionFilter
        hide={
          isApparel || (!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.YouthSizes))
        }
        attribute={SearchFiltersRefinement.YouthSizes}
        isExpanded={!!hiddenFilters && hiddenFilters.has(SearchFiltersRefinement.YouthSizes)}
        selectedFilters={getSelectedFilters('size_youth')}
        items={sortNumericLabel(
          (getSizes(filters?.sizesList?.[0]?.gendersList || [], 'US_youth') || [])
            .map((filter) => {
              if (SIZE_MAP[filter.name] || !isValidSize(filter.name, { isApparel: false })) {
                return undefined
              }
              return {
                label: filter.name,
                value: filter.name,
              }
            })
            .filter((i: any) => i),
        )}
        refine={(va) => {
          handleQueryChange('size_youth', va)
        }}
      />
      {!isApparel && !loading && !error && (
        <PriceFilterContainer
          currency={currencyIsoCode}
          attribute={
            currencyIsoCode
              ? `${SearchFiltersRefinement.LowestPrice}_${currencyIsoCode}`
              : SearchFiltersRefinement.LowestPrice
          }
          baseAttribute={SearchFiltersRefinement.LowestPrice}
          testID={{
            min: 'min-price-input',
            max: 'max-price-input',
          }}
          qaAttr={{
            min: SearchQA.FilterPriceMinAmount,
            max: SearchQA.FilterPriceMaxAmount,
            name: SearchQA.FilterPrice,
          }}
          currentRefinement={{
            min: Math.round(filters?.minimumPriceCents?.amountCents / 100),
            max: Math.round(filters?.maximumPriceCents?.amountCents / 100),
          }}
          selectedValues={{
            min: router.query?.min_price
              ? Math.round(parseInt(router?.query?.min_price))
              : undefined,
            max: router?.query?.max_price
              ? Math.round(parseInt(router?.query?.max_price))
              : undefined,
          }}
          refine={(va) => {
            handlePriceChange(va)
          }}
        />
      )}

      <ListFilter
        hide={isApparel || hiddenFilters?.has(SearchFiltersRefinement.Condition)}
        attribute={SearchFiltersRefinement.Condition}
        qaAttrs={{
          filterName: SearchQA.FilterCondition,
          mobileFilterName: SearchQA.MobileFilterConditionLabel,
          desktopFilterName: SearchQA.DesktopFilterCondition,
        }}
        items={orderByCondition(
          (filters?.productConditionsList || []).map((filter) => {
            return {
              label: filter.name,
              value: filter.name,
            }
          }),
        )}
        selectedFilters={getSelectedFilters('condition')}
        refine={(va) => {
          handleQueryChange('condition', va)
        }}
      />

      <ColorFilter
        hide={isApparel || hiddenFilters?.has(SearchFiltersRefinement.Color)}
        attribute={SearchFiltersRefinement.Color}
        items={sortColorByCountThenAlpha(
          (filters?.colorsList || []).map((filter) => {
            return {
              label: filter.name,
              value: filter.name,
            }
          }),
        )}
        selectedFilters={getSelectedFilters('color')}
        refine={(va) => {
          handleQueryChange('color', va)
        }}
      />

      {!isApparel && (
        <PriceFilterContainer
          currency={currencyIsoCode}
          attribute={SearchFiltersRefinement.Year}
          baseAttribute={SearchFiltersRefinement.Year}
          testID={{
            min: 'min-year-input',
            max: 'max-year-input',
          }}
          qaAttr={{
            min: SearchQA.FilterYearMinAmount,
            max: SearchQA.FilterYearMaxAmount,
            name: SearchQA.FilterYear,
          }}
          currentRefinement={{
            min: getMinYear(filters?.releaseYearsList),
            max: getMaxYear(filters?.releaseYearsList),
          }}
          selectedValues={{
            min: router.query?.year_min
              ? parseInt(router?.query?.year_min)
              : getMinYear(filters?.releaseYearsList),

            max: router?.query?.year_max
              ? parseInt(router?.query?.year_max)
              : getMaxYear(filters?.releaseYearsList),
          }}
          refine={(va) => {
            handleYearChange(va)
          }}
        />
      )}

      {isApparel && (
        <CategoryFilter
          attribute={SearchFiltersRefinement.ProductType}
          refine={(va) => {
            handleQueryChange('product_type', va)
          }}
          items={sortAlphanumericLabel(
            (filters?.productCategoriesList?.[0]?.productTypesList || []).map((filter) => {
              return {
                label: filter.name,
                value: filter.name,
              }
            }),
          )}
          selectedFilters={getSelectedFilters('product_type')}
        />
      )}
    </Fragment>
  )
}
ConsumerFiltersSection.displayName = 'ConsumerFiltersSection'
export default ConsumerFiltersSection
