/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import { uniqBy } from 'lodash/fp'
import { FC, useCallback } from 'react'
import { Button } from 'shared/components/Button'
import { renderUntil } from 'shared/hocs/render'
import { colors } from 'shared/lib'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import SideSheet from 'shared/components/SideSheet'
import styled from 'styled-components'

import ClearRefinementsButton, {
  ClearRefinementsButton as ConsumerClearRefinementsButton,
} from '../ClearRefinementsButton'
import FiltersSection from './FiltersSection'
import { SearchQA } from 'shared/dataAttributes'
import { t } from 'localization'
import ConsumerFiltersSection from './NewFilters/ConsumerFiltersSection'
import { useRouter } from 'next/router'
import countFilters from 'search/utils/count-filters'

const TP = 'search.components.SearchFilters.MobileSearchFiltersLayout'

const FilterButton = styled(Button)`
  width: 130px !important;
  height: 30px !important;
  min-height: 30px;
  line-height: 30px !important;
  background-color: ${colors.FC2_RED};
  border: none;
`

const FilterButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface MobileSearchFiltersProps {
  hiddenFilters: Set<string>
  show: boolean
  toggle: () => void
}

const ConsumerMobileSearchFiltersLayout = ({
  filters,
  silhouettesList,
  hiddenFilters,
  isApparel,
  show,
  toggle,
}: any) => {
  const router = useRouter()
  const filterCount = useCallback(() => {
    // count the number of non empty queries
    const queryCount = Object.keys(router?.query || {}).filter(
      (q) =>
        q &&
        q !== 'query' &&
        router.query[q].length &&
        q !== 'sortBy' &&
        q !== 'page' &&
        q !== 'slug',
    ).length

    const hasTwoPriceFilters = router?.query?.min_price && router?.query?.max_price ? -1 : 0
    const hasTwoYearFilters = router?.query?.year_min && router?.query?.year_max ? -1 : 0

    return queryCount + hasTwoPriceFilters + hasTwoYearFilters
  }, [router.query])
  return (
    <SideSheet.MobileOnly onClose={toggle} isVisible={show}>
      <SideSheet.Actions>
        <FilterButtonsWrapper>
          <ConsumerClearRefinementsButton
            items={filterCount() > 0 ? 'has items' : []}
            refine={() => {
              let newQuery: any = {}

              if (router.query?.query) {
                newQuery = { query: router.query.query }
              }

              if (router.query?.sortBy) {
                newQuery = { ...newQuery, sortBy: router }
              }

              void router.push(
                `${window.location.pathname}?` + new URLSearchParams(newQuery).toString(),
                undefined,
                {
                  shallow: true,
                },
              )
            }}
          />

          <FilterButton
            qaAttr={SearchQA.MobileViewResultsButton}
            buttonType="primary2"
            onClick={toggle}
          >
            {t(`${TP}.viewResults`, 'View Results')}
          </FilterButton>
        </FilterButtonsWrapper>
      </SideSheet.Actions>
      <SideSheet.Body>
        <FiltersWrapper>
          <ConsumerFiltersSection
            filters={filters}
            silhouettesList={silhouettesList}
            hiddenFilters={hiddenFilters}
            isApparel={isApparel}
          />
        </FiltersWrapper>
      </SideSheet.Body>
    </SideSheet.MobileOnly>
  )
}

export const NewMobileFiltersLayout = renderUntil.large(ConsumerMobileSearchFiltersLayout)

const ViewClearFilterButtons = ({ hiddenFilters, items, refine, toggle }) => {
  const dedupedItems = uniqBy('id')(items)
  const itemsWithoutHiddenFilters = dedupedItems?.filter(
    (item) => !hiddenFilters?.has(item?.attribute),
  )
  const filterCount = countFilters(itemsWithoutHiddenFilters)

  return (
    <FilterButtonsWrapper>
      {filterCount > 0 && <ClearRefinementsButton items={items} refine={refine} />}
      <FilterButton
        qaAttr={SearchQA.MobileViewResultsButton}
        buttonType="primary2"
        onClick={toggle}
      >
        {t(`${TP}.viewResults`, 'View Results')}
      </FilterButton>
    </FilterButtonsWrapper>
  )
}

const ConnectedViewClearFilterButtons = connectCurrentRefinements(ViewClearFilterButtons)

const MobileSearchFilters: FC<MobileSearchFiltersProps> = ({ hiddenFilters, show, toggle }) => {
  return (
    <SideSheet.MobileOnly onClose={toggle} isVisible={show}>
      <SideSheet.Actions>
        <ConnectedViewClearFilterButtons hiddenFilters={hiddenFilters} toggle={toggle} />
      </SideSheet.Actions>
      <SideSheet.Body>
        <FiltersWrapper>
          <FiltersSection />
        </FiltersWrapper>
      </SideSheet.Body>
    </SideSheet.MobileOnly>
  )
}

MobileSearchFilters.displayName = 'MobileSearchFilters'

export default renderUntil.large(MobileSearchFilters)
