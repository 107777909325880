/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import styled from 'styled-components'

import { SearchFiltersHumanizedRefinement } from 'search'
import { Button } from 'shared/components/Button'
import { Card } from 'shared/components/CardDeprecated'
import { SearchQA } from 'shared/dataAttributes'
import { colors } from 'shared/lib'
import { FC } from 'react'

interface CategoryFilterProps {
  attribute: string
  items: {
    label: string
    value: string
  }[]
  refine(value: string): void
  isExpanded?: boolean
  selectedFilters: any
}
const CategoryFilter: FC<CategoryFilterProps> = ({
  attribute,
  items,
  refine,
  isExpanded,
  selectedFilters,
}) => {
  const handleRefinement = (value: string) => {
    sendTrackingEvent('FILTER_APPLY_TAP', {
      page: window?.location?.href,
      filter_name: SearchFiltersHumanizedRefinement()[attribute].toLowerCase(),
      filter_selection: value[value.length - 1],
    })
    refine(value)
  }

  return items.length > 0 ? (
    // TODO: Work with QA to add qaAttr SearchQA.FilterCategory
    <Card
      title={SearchFiltersHumanizedRefinement()[attribute]}
      isExpanded={isExpanded}
      qaAttr={'SearchQA.FilterCategory'}
    >
      <CategoryCardContent>
        {items.map(({ label, value }) => (
          <CategoryOptionButton
            buttonType="secondary"
            $fill
            key={label}
            onClick={() => {
              handleRefinement(value)
            }}
            isSelected={selectedFilters.has(value)}
            qaAttr={SearchQA.FilterColorLabel}
          >
            {label.toUpperCase()}
          </CategoryOptionButton>
        ))}
      </CategoryCardContent>
    </Card>
  ) : null
}

const CategoryCardContent = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 1fr;
  padding: 20px;
`

const CategoryOptionButton = styled(Button)<{ isSelected: boolean }>`
  align-items: center;
  border-color: ${({ isSelected }) =>
    isSelected ? colors.FC2_BLACK : colors.FC2_LIGHTEST_GREY} !important;
  display: flex;
  font-size: 12px !important;
  font-weight: bold !important;
  line-height: normal !important;
  padding-left: 15px;
  text-align: left;
  text-transform: capitalize !important;
`

export default CategoryFilter
