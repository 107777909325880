import { useCallback, useRef } from 'react'

import { EXPERIMENT_NAME } from 'experiments/constants/Experiments'
import {
  logExposure as logExperiment,
  manualAssignUser as assignExperimentGroup,
} from 'experiments/services/logExposure'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { getMParticleDeviceId, getMParticleSessionId } from 'mParticle/mParticleService'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { useUser } from 'shared/hooks/useUser'

/**
 *
 * @param experimentName Darwin experiment name
 * @param forceDisable If true, it forces the service to be disabled
 */
export const useLogExperimentWithGroup = (experimentName: EXPERIMENT_NAME, groupName?: string) => {
  const areExperimentsEnabled = useFeatureFlag(FeatureFlag.WEB_FC_ENABLE_EXPERIMENTS)

  const { currentUser, currentUserType } = useUser()
  const deviceId = getMParticleDeviceId()
  const userId = currentUser?.id
  const mainId = deviceId || userId
  const sessionId = getMParticleSessionId()

  const hasLogged = useRef(false)

  const enableExperiment = areExperimentsEnabled && mainId

  // no need to keep the state
  // Darwin is idempotent
  const assignGroup = useCallback(async () => {
    if (hasLogged.current || !enableExperiment) return
    try {
      void (await assignExperimentGroup(
        experimentName,
        mainId,
        deviceId,
        sessionId,
        groupName as string,
      ))
    } catch (error) {
      // error assigning group
    }

    try {
      await logExperiment(experimentName, mainId, deviceId, sessionId, {
        userId,
        currentUserType,
      })
      hasLogged.current = true
    } catch (error) {
      // error assigning group
    }
  }, [
    enableExperiment,
    experimentName,
    mainId,
    deviceId,
    sessionId,
    groupName,
    userId,
    currentUserType,
  ])

  return {
    assignGroup,
  }
}
