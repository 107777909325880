import { FC } from 'react'
import { connectCurrentRefinements } from 'react-instantsearch-dom'
import { colors, fonts } from 'shared/lib'
import styled from 'styled-components'
import { SearchQA } from 'shared/dataAttributes'
import { t } from 'localization'

const TP = 'search.components.ClearRefinementsButton'

const Button = styled.button`
  border: none;
  background: none;
  margin: 0;
  ${fonts.CTA_TEXT_2}
  padding: 0 10px;
  &:disabled {
    color: ${colors.FC2_GREY};
  }
`
interface ClearRefinementsButtonProps {
  items
  refine
  transformItems?: (...args: any[]) => any
}

export const ClearRefinementsButton: FC<ClearRefinementsButtonProps> = ({ items, refine }) => {
  return items?.length > 0 ? (
    <Button
      data-qa={SearchQA.GridClearFiltersLink}
      onClick={() => {
        return refine?.(items)
      }}
      disabled={!items.length}
    >
      {t(`${TP}.clearFilters`, 'Clear Filters')}
    </Button>
  ) : (
    <></>
  )
}
export default ClearRefinementsButton
