import { FC } from 'react'
import MobileSideSheet, { IMobileSideSheet } from './MobileSideSheet'
import ResponsiveSideSheet, { ResponsiveSideSheetProps } from './ResponsiveSideSheet'
import ActionButton from './SideSheetActionButton'

type SideSheetComponents = {
  Actions: FC
  Body: FC
  Footer: FC
  ModalOverlay: FC
  ActionButton: FC
  MobileOnly: FC<IMobileSideSheet>
  Responsive: FC<ResponsiveSideSheetProps>
}

const SideSheet: SideSheetComponents = {
  MobileOnly: MobileSideSheet,
  Responsive: ResponsiveSideSheet,
  Actions: ({ children }) => <>{children}</>,
  ModalOverlay: ({ children }) => <>{children}</>,
  Body: ({ children }) => <>{children}</>,
  Footer: ({ children }) => <>{children}</>,
  ActionButton: ActionButton,
}

SideSheet.Actions.displayName = 'SideSheet.Actions'
SideSheet.ModalOverlay.displayName = 'SideSheet.ModalOverlay>'
SideSheet.Body.displayName = 'SideSheet.Body'
SideSheet.Footer.displayName = 'SideSheet.Footer'

export default SideSheet
