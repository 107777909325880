import { isEmpty, toUpper } from 'lodash/fp'
import { FC } from 'react'
import { SearchFiltersHumanizedRefinement } from 'search'
import { Card as BaseCard } from 'shared/components/CardDeprecated'
import styled from 'styled-components'

import { hide as hideUtil } from 'shared/lib'
import { SizeTile, TileGrid } from './styles'

const Card = styled(BaseCard)<{ isHidden: boolean }>`
  ${({ isHidden }) => hideUtil({ isHidden })};
`

interface PresentationSizeFilterProps {
  attribute: string
  hide: boolean
  items: {
    label: string
    value: string
  }[]
  refine(value: string): void
  isExpanded?: boolean
  selectedFilters: Set<string>
}

const PresentationSizeFilter: FC<PresentationSizeFilterProps> = ({
  hide,
  attribute,
  items,
  refine,
  isExpanded,
  selectedFilters
}) => {
  return !isEmpty(items) ? (
    <Card
      title={SearchFiltersHumanizedRefinement()[attribute]}
      isExpanded={isExpanded}
      isHidden={hide}
    >
      <TileGrid>
        {items.map(({ label, value }) => (
          <SizeTile
            key={label}
            isSelected={selectedFilters.has(value)}
            onClick={() => {
              refine(value)
            }}
          >
            {toUpper(label)}
          </SizeTile>
        ))}
      </TileGrid>
    </Card>
  ) : null
}

export default PresentationSizeFilter
