import { FC } from 'react'
import { Checkbox } from 'shared/components/Checkbox'
import { renderStart } from 'shared/hocs/render'
import styled from 'styled-components'
import { IRefinementListProps } from '../IRefinementListProps'

const ConditionsCardContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  padding: 20px;
`

// todo: is selected

const DesktopListFilter: FC<IRefinementListProps> = ({
  items,
  refine,
  qaAttr,
  selectedFilters,
}) => {
  return (
    <ConditionsCardContent>
      {items.map(({ label, value }) => (
        <Checkbox
          key={label}
          label={label}
          onChange={() => {
            refine(value)
          }}
          checked={selectedFilters.has(value)}
          qaAttr={qaAttr}
        />
      ))}
    </ConditionsCardContent>
  )
}

DesktopListFilter.displayName = 'DesktopListFilter'

export default renderStart.large(DesktopListFilter)
