/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import {
  Product,
  ProductCondition,
  SalesChannel,
  SizeFilterGender,
} from '@goatapp/protos-gen-node/buying/consumer_search/api/v1/server_pb'
import { SearchHit } from './SearchMenu'
import getConfig from 'next/config'
import { getTimeStamps } from 'shared/utils/algolia-utils'
export const redirectRoutesFilters: any = {
  'adidas-yeezy': {
    silhouette: ['yeezy'],
    brand: ['adidas'],
  },
  'nike-yeezy': {
    silhouette: ['yeezy'],
    brand: ['nike'],
  },
  'foam-runner': {
    collection: 'yeezy-foam-runner',
  },
  slides: {
    collection: 'yeezy-slides',
  },
  'nike-basketball': {
    activity: ['basketball'],
    brand: ['nike'],
  },
  'nike-cross-training': {
    activity: ['cross-training'],
    brand: ['nike'],
  },
  'nike-running': {
    activity: ['running'],
    brand: ['nike'],
  },
  'nike-skateboarding': {
    activity: ['skateboarding'],
    brand: ['nike'],
  },
}
export const getSearchForSlug = (slug: string) => {
  if (slug === 'men' || slug === 'women' || slug === 'youth') {
    return {
      gender: [slug],
    }
  }

  if (slug === 'new-releases') {
    return {
      collection: 'new-releases',
    }
  }

  if (slug === 'apparel') {
    return {
      category: ['apparel'],
    }
  }

  if (slug === 'sneakers') {
    return {}
  }

  if (slug === 'sneakers-under-250') {
    return {
      pricemax: '25000',
    }
  }

  if (slug === 'sneakers-under-350') {
    return {
      pricemax: '35000',
    }
  }

  if (slug === 'price-drops') {
    return {
      collection: 'price-drops',
    }
  }

  if (slug === 'pre-owned') {
    return {
      collection: 'pre-owned',
    }
  }

  return undefined
}
export const consumerSearchConversionSizes = (
  sizes: { [key: string]: string[] }[],
  type: string | number,
) => {
  const allSizes: {
    [key: string]: string | number
  }[] = []
  sizes.forEach((size) => {
    const key = Object.keys(size)[0]
    const sizeValue = size[key]
    const [, unit, gender] = (key || '').toLowerCase().split('_')

    sizeValue.forEach((size) => {
      allSizes.push({
        value: size,
        gender:
          gender === 'men'
            ? SizeFilterGender.SIZE_FILTER_GENDER_MEN
            : gender === 'women'
            ? SizeFilterGender.SIZE_FILTER_GENDER_WOMEN
            : SizeFilterGender.SIZE_FILTER_GENDER_YOUTH,
        type: type || '',
        unit: (unit || type === 'apparel' ? '' : 'us').toUpperCase(),
      })
    })
  })

  return allSizes
}

export const PRODUCT_CONDITION_MAP: {
  [key: string]: {
    name: string
    value: ProductCondition
    key: string
  }
} = {
  new_no_defects: {
    name: 'New',
    value: ProductCondition.NEW_NO_DEFECTS,
    key: 'new_no_defects',
  },
  new_with_defects: {
    name: 'New with Defect',
    value: ProductCondition.NEW_WITH_DEFECTS,
    key: 'new_with_defects',
  },
  goat_clean: {
    name: 'GOAT Clean',
    value: ProductCondition.GOAT_CLEAN,
    key: 'goat_clean',
  },
  used: {
    name: 'Used',
    value: ProductCondition.USED,
    key: 'used',
  },
}

export const toAlgoliaSearchProduct = (data: Product.AsObject[] = []) => {
  return data.map((item: Product.AsObject) => {
    const newData = {} as SearchHit
    newData.grid_picture_url = item.pictureUrl
    newData.name = item.title
    newData.slug = item.slug

    return newData as SearchHit
  })
}

export const toAlgoliaProductHit = (data: Product.AsObject[] = []) => {
  const { publicRuntimeConfig } = getConfig()
  return data.map((item: Product.AsObject) => {
    const newData = {} as any
    newData.pictureUrl = item.pictureUrl || publicRuntimeConfig.IMAGE_FC_CDN + 'missing.png?w=360'
    newData.name = item.title
    newData.slug = item.slug

    newData.id = item.id
    newData.price = {
      localizedValue:
        item?.variantsList?.[0]?.localizedLowestPriceCents?.amountCents !== undefined
          ? (item?.variantsList?.[0]?.localizedLowestPriceCents?.amountCents || 0) / 100
          : null,
    }

    newData.brandName = item.brandName

    return newData as any
  })
}

export const toConsumerSearchFilters = (algoliaFilters: {
  page: number
  query: string
  sortType: number
  pageLimit?: string
  range: {
    [key: string]: {
      min: string
      max: string
    }
  }
  category?: string[]
  refinementList: {
    [key: string]: string[]
  }
  collection?: string
  type?: string[]
  country: string
  currency: string
  gender?: string[]
  isClientOnly?: boolean
  pricemin?: number
  pricemax?: number
  brand?: string[]
  activity?: string[]
  silhouette?: string[]
}) => {
  const {
    page,
    pageLimit,
    query,
    range,
    refinementList,
    collection,
    country,
    currency,
    sortType,
    category,
    gender,
    isClientOnly,
    pricemin,
    pricemax,
    activity,
  } = algoliaFilters

  const pricesCent =
    Object.keys(range || {}).filter((key) => key.includes('price'))[0] || 'lowest_price_cents_usd'

  const sizesKeys = Object.keys(refinementList || {}).filter((key) => key.includes('size_'))
  const categoryFilters = refinementList?.product_category?.includes('clothing')
    ? ['apparel', ...(refinementList.product_category || [])]
    : refinementList?.product_category || []
  const productTypeFilters = refinementList?.product_type || []

  const filtersParams = {
    categories: categoryFilters,
    activities: activity || [],
    conditions: (refinementList?.shoe_condition || []).map(
      (condition: string) => PRODUCT_CONDITION_MAP[condition].value,
    ),
    productTypes: productTypeFilters,
    silhouettesList: refinementList?.silhouette || [],
    brands: refinementList?.brand || refinementList?.brand_name || [],
    colors: refinementList?.color || [],
    genders: gender || refinementList?.single_gender || [],
    sizes:
      sizesKeys.length > 0
        ? consumerSearchConversionSizes(
            sizesKeys.map((refinement: string) => ({
              [refinement]: refinementList[refinement],
            })),
            !category ? 1 : '',
          )
        : [],
    releaseDateStart: range?.release_year?.min
      ? getTimeStamps({ year: parseInt(range?.release_year?.min) })
      : undefined,
    releaseDateEnd: range?.release_year?.max
      ? getTimeStamps({ year: parseInt(range?.release_year?.max) })
      : undefined,
  }

  const queryString: Record<string, string | any> = {
    salesChannelId: `${SalesChannel.SALES_CHANNEL_FLIGHT_CLUB}`,
    queryString: query || '',
    sortType: sortType.toString(),
    pageLimit: pageLimit || '30',
    pageNumber: (page || 1).toString(),
    includeAggregations: true.toString(),
    collectionSlug: refinementList?.collection_slugs?.[0] || collection || '',
    priceCentsMin: pricemin || range?.[pricesCent]?.min,
    priceCentsMax: pricemax || range?.[pricesCent]?.max,
    productFilter: isClientOnly ? filtersParams : JSON.stringify(filtersParams),
    vector: '',
    country: country || 'US',
    currency: currency || 'USD',
  }

  return queryString
}
