/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import { renderStart } from 'shared/hocs/render'
import styled from 'styled-components'

import FiltersSection from './FiltersSection'
import ConsumerFiltersSection from './NewFilters/ConsumerFiltersSection'

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const DesktopSearchFilters = () => {
  return (
    <FiltersWrapper>
      <FiltersSection />
    </FiltersWrapper>
  )
}

const ConsumerDesktopSearchFilters = ({
  filters,
  silhouettesList,
  hiddenFilters,
  isApparel,
}: any) => {
  return (
    <FiltersWrapper>
      <ConsumerFiltersSection
        filters={filters}
        silhouettesList={silhouettesList}
        hiddenFilters={hiddenFilters}
        isApparel={isApparel}
      />
    </FiltersWrapper>
  )
}

export const NewDesktopFilters = renderStart.large(ConsumerDesktopSearchFilters)

export default renderStart.large(DesktopSearchFilters)
