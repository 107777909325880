/* eslint-disable @typescript-eslint/no-explicit-any */
// TODO: find out any for new consumer search data
import { isEmpty, capitalize, split, get, pipe } from 'lodash/fp'
import { FC } from 'react'
import { SearchFiltersHumanizedRefinement } from 'search'
import { Card as BaseCard } from 'shared/components/CardDeprecated'
import styled from 'styled-components'
import { SearchQA } from 'shared/dataAttributes'

import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { SizeTile, TileGrid } from './styles'

const Card = styled(BaseCard)<{ isHidden: boolean }>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
`

interface FilterSizeSelectionProps {
  attribute: string
  hide: boolean
  items: {
    label: string
    value: string
  }[]
  refine(value: string): void
  isExpanded?: boolean
  selectedFilters: any
}
const SizeSelectionFilter: FC<FilterSizeSelectionProps> = ({
  hide,
  attribute,
  items,
  refine,
  isExpanded,
  selectedFilters,
}) => {
  const getGender = pipe(split('_'), get(2), capitalize)
  const gender = getGender(attribute)

  const handleRefinement = (value: string) => {
    const modifiedFilterName = attribute.replace('_us_', 's_')
    sendTrackingEvent('FILTER_APPLY_TAP', {
      page: window?.location?.href,
      filter_name: modifiedFilterName.toLowerCase(),
      filter_selection: value,
    })
    refine(value)
  }

  return !isEmpty(items) ? (
    <Card
      title={SearchFiltersHumanizedRefinement()[attribute]}
      isExpanded={isExpanded}
      isHidden={hide}
      qaAttr={`${SearchQA.Filter}${gender}`}
    >
      <TileGrid>
        {items.map(({ label, value }) => (
          <SizeTile
            data-qa={`${SearchQA.FilterGenderOptions}${gender}`}
            key={label}
            isSelected={selectedFilters.has(value)}
            onClick={() => {
              handleRefinement(value)
            }}
          >
            {label}
          </SizeTile>
        ))}
      </TileGrid>
    </Card>
  ) : null
}

export default SizeSelectionFilter
